<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>

        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Section Entry</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form @submit.prevent="saveSection" ref="sectionForm" class="custom-form">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="1" class="py-1 caption text-center pe-0">Department</v-col>
                                        <v-col cols="3" class="py-0">
                                            <v-combobox dense outlined hide-details
                                                :items="$store.getters['department/departments']" v-model="department"
                                                item-text="name" item-value="id" clearable
                                                :loading="$store.getters['department/loadingDepartments']"
                                                @focus="$store.dispatch('department/getDepartments')"
                                                class="custom-form"></v-combobox>
                                        </v-col>
                                        <v-col cols="1" class="py-1 caption text-center">Name</v-col>
                                        <v-col cols="3" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="section.name"
                                                :error-messages="validation_errors.name"></v-text-field>
                                        </v-col>


                                        <v-col cols="3" class="py-0">
                                            <v-btn type="submit" height="26px" dark color="light-blue darken-2"
                                                :elevation="1" class="mr-1" :loading="loading">Save</v-btn>
                                            <v-btn height="26px" dark color="deep-orange" :elevation="1"
                                                @click="resetForm">Cancel</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table class="custom-data-table elevation-1" dense :headers="sectionHeaders"
                    :items="$store.getters['section/sections']" :search="searchSection">
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Section List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field outlined dense hide-details placeholder="Search Section"
                                    append-icon="mdi-magnify" style="width: 300px" v-model="searchSection">
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="editSection(item)" color="primary"
                                    v-on="on">mdi-circle-edit-outline</v-icon>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="deleteSection(item)" color="error"
                                    v-on="on">mdi-delete-circle-outline</v-icon>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Sections",

    data() {
        return {
            sectionHeaders: [
                { text: "Name", value: "name" },
                { text: "Department", value: "department.name" },
                { text: "Action", value: "action" },
            ],
            searchSection: "",

            section: {
                id: "",
                department_id: "",
                name: "",
            },
            department: null,
            editId: null,
            validation_errors: {},
            loading: false,
        };
    },

    created() {
        this.$store.dispatch("section/getSections");
    },

    methods: {
        async saveSection() {
            if (!this.$refs.sectionForm.validate()) return;

            this.loading = true;

            this.section.department_id = this.department.id ?? null;
            let data = {
                url: "/add-section",
                section: this.section,
            };

            if (this.editId) {
                data.url = "/update-section";
                data.section.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "section/saveSection",
                data
            );

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if (resObj.isSuccess) {
                this.resetForm();
            }

        },
        editSection(section) {
            Object.keys(this.section).forEach(
                (k) => (this.section[k] = section[k])
            );
            this.editId = section.id;

            this.department = {
                id: section.department_id,
                name: section.department.name
            }
        },
        deleteSection(section) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch("section/deleteSection", section.id);
        },
        resetForm() {
            Object.keys(this.section).forEach(
                (k) => (this.section[k] = "")
            );

            this.editId = null;
            this.department = null;
            this.$refs.sectionForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style></style>
