<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>

        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Line Entry</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form @submit.prevent="saveLine" ref="lineForm" class="custom-form">
                            <v-row class="justify-center">
                                <v-col cols="4" offet="4">
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption text-end">Section</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-combobox dense outlined hide-details
                                                :items="$store.getters['section/sections']" v-model="section"
                                                item-text="name" item-value="id" clearable
                                                :loading="$store.getters['section/loadingSections']"
                                                @focus="$store.dispatch('section/getSections')"
                                                class="custom-form"></v-combobox>
                                        </v-col>
                                        <v-col cols="4" class="py-1 caption text-end">Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="line.name"
                                                :error-messages="validation_errors.name"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-1 caption text-end">BN Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="line.bn_name"
                                                :error-messages="validation_errors.bn_name"></v-text-field>
                                        </v-col>


                                        <v-col cols="12" class="py-0 text-end">
                                            <v-btn height="26px" class="mr-1" dark color="deep-orange" :elevation="1"
                                                @click="resetForm">Cancel</v-btn>
                                            <v-btn type="submit" height="26px" dark color="light-blue darken-2"
                                                :elevation="1" :loading="loading">Save</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table class="custom-data-table elevation-1" dense :headers="lineHeaders"
                    :items="$store.getters['line/lines']" :search="searchLine">
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Line List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field outlined dense hide-details placeholder="Search Line"
                                    append-icon="mdi-magnify" style="width: 300px" v-model="searchLine">
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="editLine(item)" color="primary"
                                    v-on="on">mdi-circle-edit-outline</v-icon>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="deleteLine(item)" color="error"
                                    v-on="on">mdi-delete-circle-outline</v-icon>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Lines",

    data() {
        return {
            lineHeaders: [
                { text: "Name", value: "name" },
                { text: "BN Name", value: "bn_name" },
                { text: "Section", value: "section.name" },
                { text: "Action", value: "action" },
            ],
            searchLine: "",

            line: {
                id: "",
                section_id: "",
                name: "",
                bn_name: "",
            },
            section: null,
            editId: null,
            validation_errors: {},
            loading: false,
        };
    },

    created() {
        this.$store.dispatch("line/getLines");
    },

    methods: {
        async saveLine() {
            if (!this.$refs.lineForm.validate()) return;

            this.loading = true;

            this.line.section_id = this.section.id ?? null;
            let data = {
                url: "/add-line",
                line: this.line,
            };

            if (this.editId) {
                data.url = "/update-line";
                data.line.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "line/saveLine",
                data
            );

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if (resObj.isSuccess) {
                this.resetForm();
            }

        },
        editLine(line) {
            Object.keys(this.line).forEach(
                (k) => (this.line[k] = line[k])
            );
            this.editId = line.id;

            this.section = {
                id: line.section_id,
                name: line.section.name
            }
        },
        deleteLine(line) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch("line/deleteLine", line.id);
        },
        resetForm() {
            Object.keys(this.line).forEach(
                (k) => (this.line[k] = "")
            );

            this.editId = null;
            this.section = null;
            this.$refs.lineForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style></style>
